var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Clientes Cashback")]),_c('v-layout',{attrs:{"child-flex":""}},[_c('v-data-table',{attrs:{"headers":_vm.headerGrid,"items":_vm.itensGrid,"search":_vm.keyFindGrid,"dense":"","fixed-header":"","items-per-page":50,"height":"75vh","no-data-text":"Ops!! Não tem nenhuma informação aqui!!!","no-results-text":"Ops!! Nenhum informção foi localizada!!!","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              itemsPerPageOptions: [50, 100],
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Procurar","dense":"","single-line":"","hide-details":""},model:{value:(_vm.keyFindGrid),callback:function ($$v) {_vm.keyFindGrid=$$v},expression:"keyFindGrid"}})],1)]},proxy:true},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"dark":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.goCashBack(item.CODCLI)}}},[_c('v-icon',{attrs:{"x-small":"","dark":""}},[_vm._v(" mdi-link")])],1)]}}],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }