var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mb-2"},[_c('v-row',{staticClass:"my-2"},[_c('v-col',[_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("Total: "),_c('span',{staticClass:"caption green--text"},[_vm._v(_vm._s(_vm._f("numeral")(_vm.vlrTotalCab,"0,0.00")))])])])],1),_c('v-col',[_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("Status: "),_c('span',{class:[ `caption`, _vm.vlrTotalSaldoCab >= 0 ? `green--text` : `red--text`,]},[_vm._v(_vm._s(_vm.descStatusPedido))])])])],1),_c('v-col',[(_vm.existeItemSuperDesconto)?_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("CMV: "),_c('span',{class:[`caption`, _vm.vlrCmv >= 0 ? `blue--text` : `red--text`,]},[_vm._v(_vm._s(_vm._f("numeral")(_vm.vlrCmv,"0,0.00"))+" % ")])])]):_vm._e()],1),_c('v-col',[(_vm.existeItemSuperDesconto)?_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("Representatividade: "),_c('span',{class:[`caption`, _vm.vlrRepresentatividade >= 0 ? `blue--text` : `red--text`,]},[_vm._v(_vm._s(_vm._f("numeral")(_vm.vlrRepresentatividade,"0,0.00"))+" % ")])])]):_vm._e()],1)],1),(_vm.getColunasIndustria)?_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("Saldo: "),_c('span',{class:[`caption`, _vm.vlrTotalSaldoCab >= 0 ? `blue--text` : `red--text`,]},[_vm._v(_vm._s(_vm._f("numeral")(_vm.vlrTotalSaldoCab,"0,0.00")))])])])],1),_c('v-col',[_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("Brinde: "),_c('span',{class:[
                            `caption`,
                            _vm.vlrTotalBrindeCab >= 0 ? `blue--text` : `red--text`,
                          ]},[_vm._v(_vm._s(_vm._f("numeral")(_vm.vlrTotalBrindeCab,"0,0.00"))+" ")])])])],1),_c('v-col',[_c('v-row',{attrs:{"justify":"start"}},[_c('span',{staticClass:"caption black--text"},[_vm._v("Saldo Brinde: "),_c('span',{class:[
                            `caption`,
                            _vm.vlrTotalBrindeCab + _vm.vlrTotalSaldoCab>= 0 ? `blue--text` : `red--text`,
                          ]},[_vm._v(_vm._s(_vm._f("numeral")(_vm.vlrTotalBrindeCab + _vm.vlrTotalSaldoCab,"0,0.00"))+" ")])])])],1),_c('v-col')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }