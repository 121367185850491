
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import SendMsg from "@/lib/sendMsg";
import Totalizador from "@/screens/Feira/Pedido/Totalizador.vue";
import {Route} from 'vue-router'
import EspelhoPedido from "@/screens/Feira/Pedido/EspelhoPedido.vue";

@Component({
  components: {EspelhoPedido, Totalizador},
})
export default class PedidoVendaFeira extends Vue {
  @Prop({default: "S"}) propEditando: string | undefined;
  @Prop({default: 0}) propCodPedido: number | undefined;
  @Prop({default: 0}) propCodFeira: number | undefined;
  @Prop({default: 0}) propSeqFeira: number | undefined;
  @Prop({default: ""}) propCodFilial: string | undefined;
  @Prop({default: 0}) propCodCli: number | undefined;
  @Prop({default: 0}) propCodPlPag: number | undefined;
  @Prop({default: 0}) propCodPlPagCompl: number | undefined;
  @Prop({default: 0}) propCodTabPrecoNegCab: number | undefined;
  @Prop({default: 0}) propCodUsur: number | undefined;
  @Prop({default: ""}) propObservacao: string | undefined;
  @Prop({default: ""}) propObservacaoLiberacao: string | undefined;
  @Prop({default: ""}) propStatus: string | undefined;
  @Prop({default: ""}) propUtilizaPlanoCompl: string | undefined;
  // variáveis comuns de controle da página
  private baseURL = "https://server.grupopalmed.com.br:4004/api/";
  //private baseURL = "http://localhost:4005/api/";
  private pedido = null;
  private dialogCancelarPedido = false;
  private dialogGravarPedido = false;
  private dialogGravarPedidoLog = false;
  private dialogGravando = false;
  private dialogGravado = false;
  private dialogMultiplicador = false;
  private dialogItemBonificado = false;
  private idxItemBonifado = -1;
  private itemBonificado = null;
  private msgDialogGravado = "";
  private formValid = false;
  private keyFindGridItens = "";
  private keyFindGridBrindes = "";
  private keyFindGridBonificacao = "";
  private colunasIndustria = false;
  private captionInfoInd = "";
  private descStatusPedido = "Nenhum Pedido";
  private isLoadingItemsPedido = false;
  private isLoadingBrindes = false;
  private isLoadingBonificacao = false;
  private isSavePedido = false;
  private descriptionLimit = 60;
  private pedidoRelatorio = null;
  // variáveis do pedido
  private tab = 0;
  private pedidoIniciado = false;
  private modelFeira = null;
  private modelFilial = null;
  private modelCliente = null;
  private modelTabNeg = null;
  private modelPlanoPag = null;
  private modelPlanoPagCompl = null;
  private modelRCA = null;
  private statuspedido = 'PL';
  private espelhoPorEmail = false;
  private utilizaPlanoCompl = false;
  private observacao = "";
  private observacaoLiberacao = "";
  private vlrTotalCab = 0;
  private vlrTotalProdNormal = 0;
  private vlrTotalProdSuperDesconto = 0;
  private existeItemSuperDesconto = false;
  private vlrTotalCustoProdNormal = 0;
  private vlrTotalCustoProdSuperDesconto = 0;
  private pctCMVProd = 0;
  private pctRepresentatividade = 0;
  private qtdeTotalSKU = 0;
  private vlrTotalBrindeCab = 0;
  private vlrTotalSaldoCab = 0;
  private statusLog = "";
  private vlrTotalSaldoBrindeBonificacao = 0;
  private vlrTotalBrinde = 0;
  private vlrTotalBrindeGeral = 0;
  private vlrTotalBonificacao = 0;
  private pctUsoSaldo = 0;
  // variáveis de carga de dados para o formulário
  private entriesFeira = [];
  private entriesFilial = [];
  private isLoadingFeira = false;
  private isLoadingFilial = false;
  private searchFeira = null;
  private searchFilial = null;
  private rulesFeira = [(v: any) => !!v || "Feira é obrigatório"];
  private rulesFilial = [(v: any) => !!v || "Filial é obrigatório"];
  private entriesCliente = [];
  private isLoadingCLiente = false;
  private searchCliente = null;
  private rulesCliente = [(v: any) => !!v || "Cliente é obrigatório"];
  private entriesTabNeg = [];
  private isLoadingTabNeg = false;
  private searchTabNeg = null;
  private rulesTabNeg = [
    (v: any) => !!v || "Table de Negociação é obrigatório",
  ];
  private entriesPlanoPag = [];
  private entriesPlanoPagCompl = [];
  private isLoadingPlanoPag = false;
  private isLoadingPlanoPagCompl = false;
  private searchPlanoPag = null;
  private searchPlanoPagCompl = null;
  private rulesPlanoPag = [
    (v: any) => !!v || "Plano de Pagamento é obrigatório",
  ];
  private rulesPlanoPagCompl = [
    (v: any) => (!this.utilizaPlanoCompl) ? !!v || "Plano de Pagamento é obrigatório" : true,
  ];
  private entriesRCA = [];
  private isLoadingRCA = false;
  private searchRCA = null;
  private rulesRCA = [(v: any) => !!v || "RCA é obrigatório"];
  private rulesObs = [
    (v: any) =>
      !(v.length > 50) ||
      "Só é permitido texto até o tamanho máximo de 50 carater.",
  ];
  private rulesObsliberacao = [
    (v: any) =>
      !(v.length > 3800) ||
      "Só é permitido texto até o tamanho máximo de 3800 carater.",
  ];
  private dialog = false;
  private gridItemsPerPage = -1;
  private itemsPedidoHeaders = [];
  private itemsPedido = [];
  private editedItemIndex = -1;
  private editedItem = {};
  private editedBrinde = {};
  private defaultItem = {};
  private exibirInfoInd = false;
  private qtdeMultiplicadora = 2;
  private qtdeItemBonificado = 0;
  private brindesHeaders = [];
  private brindesPedido = [];
  private bonificacoesHeaders = [];
  private bonificacoesPedido = [];
  private datasPrevisaoEntrega = [];
  private menuPerido = false;
  private gravacaoMensagem = "";
  private gravacaoCodPedido = 0;

  mounted() {
    this.inicializar(false);
  }

  getTitulo() {
    return this.propEditando === "S" ? "Pedido Feira - (Novo/Editando)" : "Pedido Feira - (Visualização)";
  }

  getPermitirLiberar() {
    if (this.modelFeira === null) return false;
    return (this.modelFeira as any).PERMITIRLIBERAR === 'S';
  }

  inicializar(limparProps: boolean) {
    this.tab = 0;
    this.pedido = null
    this.dialogCancelarPedido = false;
    this.dialogGravarPedido = false;
    this.dialogGravarPedidoLog = false;
    this.dialogGravando = false;
    this.dialogGravado = false;
    this.dialogMultiplicador = false;
    this.dialogItemBonificado = false;
    this.idxItemBonifado = -1;
    this.itemBonificado = null;
    this.keyFindGridItens = "";
    this.keyFindGridBrindes = "";
    this.keyFindGridBonificacao = "";
    this.colunasIndustria = false;
    this.captionInfoInd = "Ind.";
    this.descStatusPedido = "Nenhum Pedido";
    this.isLoadingItemsPedido = false;
    this.isLoadingBrindes = false;
    this.isLoadingBonificacao = false;
    this.isSavePedido = false;
    this.descriptionLimit = 100;
    this.pedidoIniciado = false;
    this.modelFeira = null;
    this.modelFilial = null;
    this.modelCliente = null;
    this.modelTabNeg = null;
    this.modelPlanoPag = null;
    this.modelPlanoPagCompl = null;
    this.modelRCA = null;
    this.statuspedido = 'PL';
    this.espelhoPorEmail = false;
    this.utilizaPlanoCompl = false;
    this.observacao = "";
    this.observacaoLiberacao = "";
    this.vlrTotalCab = 0;
    this.vlrTotalProdNormal = 0;
    this.vlrTotalProdSuperDesconto = 0;
    this.existeItemSuperDesconto = false;
    this.vlrTotalCustoProdNormal = 0;
    this.vlrTotalCustoProdSuperDesconto = 0;
    this.pctCMVProd = 0;
    this.pctRepresentatividade = 0;

    this.qtdeTotalSKU = 0;
    this.vlrTotalBrindeCab = 0;
    this.vlrTotalSaldoCab = 0;
    this.vlrTotalBrinde = 0;
    this.vlrTotalBrindeGeral = 0;
    this.vlrTotalSaldoBrindeBonificacao = 0;
    this.pctUsoSaldo = 0;
    this.vlrTotalBonificacao = 0;
    this.statusLog = "";
    this.qtdeMultiplicadora = 2;
    this.qtdeItemBonificado = 0;
    this.entriesFeira = [];
    this.entriesFilial = [];
    this.isLoadingFeira = false;
    this.isLoadingFilial = false;
    this.searchFeira = null;
    this.searchFilial = null;
    this.entriesCliente = [];
    this.isLoadingCLiente = false;
    this.searchCliente = null;
    this.entriesTabNeg = [];
    this.isLoadingTabNeg = false;
    this.searchTabNeg = null;
    this.entriesPlanoPag = [];
    this.entriesPlanoPagCompl = [];
    this.isLoadingPlanoPag = false;
    this.isLoadingPlanoPagCompl = false;
    this.searchPlanoPag = null;
    this.searchPlanoPagCompl = null;
    this.entriesRCA = [];
    this.isLoadingRCA = false;
    this.searchRCA = null;
    this.dialog = false;
    this.gridItemsPerPage = -1;
    this.editedItemIndex = -1;
    this.editedItem = {};
    this.editedBrinde = {};
    this.defaultItem = {};
    this.datasPrevisaoEntrega = [];
    this.menuPerido = false;
    this.pedidoRelatorio = null;
    this.gravacaoMensagem = "";
    this.gravacaoCodPedido = 0;
    this.itemsPedidoHeaders = [
      {
        text: "Sup. Desc.",
        align: "start",
        divider: "false",
        value: "SUPER_DESCONTO",
        width: 20,
        class: "cyan lighten-4 caption",
      },
      {
        text: "Código",
        align: "start",
        divider: "false",
        value: "CODPROD",
        width: 30,
        class: "cyan lighten-4 caption",
      },
      {
        text: "Produto",
        value: "PRODUTO",
        divider: "false",
        class: "cyan lighten-4 caption",
      },
      {
        text: "Marca",
        value: "MARCA",
        divider: "false",
        class: "cyan lighten-4 caption",
      },
      {
        text: "CX. Padrão",
        value: "QTUNITCX",
        align: "center",
        divider: "false",
        filterable: false,
        width: 40,
        class: "cyan lighten-4 caption",
      },
      {
        text: "Estoque Disp.",
        value: "QTDEESTDISP",
        align: "end",
        divider: "false",
        filterable: false,
        width: 25,
        class: "teal lighten-2 caption",
      },
      {
        text: "P. F.",
        value: "VLRVENDAFAB",
        align: "end",
        divider: "false",
        filterable: false,
        width: 30,
        class: "teal lighten-4 caption",
      },
      {
        text: "% Desconto",
        value: "PERCDESCPED",
        align: "end",
        divider: "false",
        filterable: false,
        width: 20,
        class: "teal lighten-4 caption",
      },
      {
        text: "P. Venda Unit.",
        value: "VLRVENDAPED",
        align: "end",
        divider: "false",
        filterable: false,
        width: 30,
        class: "teal lighten-4 caption",
      },
      {
        text: "Quantidade",
        value: "QTDEPED",
        align: "end",
        divider: "false",
        filterable: false,
        width: 70,
        class: "teal lighten-2 caption",
      },
      {
        text: "Total",
        value: "VLRTOTALPED",
        align: "end",
        divider: "false",
        filterable: false,
        width: 70,
        class: "teal lighten-2 caption",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        divider: "false",
        sortable: false,
        filterable: false,
        width: 20,
        class: "cyan lighten-4 caption",
      },
      {
        text: "Ações",
        value: "actions",
        align: "center",
        divider: "false",
        sortable: false,
        filterable: false,
        width: 20,
        class: "cyan lighten-4 caption",
      },
    ] as any;
    this.searchFilialWatch();
    this.searchFeiraWatch();
    this.searchClienteWatch();
    this.itemsPedido = [];

    this.brindesHeaders = [
      {
        text: "Brinde",
        value: "BRINDE",
        divider: "false",
      },
      {
        text: "Qtde.",
        value: "QUANTIDADE",
        align: "end",
        filterable: false,
        divider: "false",
        width: 40,
      },
      {
        text: "Ações",
        value: "actions",
        align: "center",
        divider: "false",
        sortable: false,
        filterable: false,
        width: 120,
      },
      {
        text: "Valor Unitário",
        value: "VALOR_UNITARIO",
        align: "end",
        divider: "false",
        filterable: false,
        width: 40,
      },
      {
        text: "Valor Total",
        value: "VALOR_TOTAL",
        align: "end",
        divider: "false",
        filterable: false,
        width: 40,
      },
    ] as any;
    this.brindesPedido = [];

    this.bonificacoesHeaders = [
      {
        text: "Cód. Prod.",
        value: "CODPROD",
        divider: "false",
        width: 50,
      },
      {
        text: "Produto Bonificação",
        value: "PRODUTO",
        divider: "false",
      },
      {
        text: "Estoque Disp.",
        value: "ESTOQUE_DISP",
        align: "end",
        filterable: false,
        divider: "false",
        width: 40,
      },
      {
        text: "Qtde.",
        value: "QUANTIDADE",
        align: "end",
        filterable: false,
        divider: "false",
        width: 40,
      },
      {
        text: "Ações",
        value: "actions",
        align: "center",
        divider: "false",
        sortable: false,
        filterable: false,
        width: 120,
      },
      {
        text: "Valor Unitário",
        value: "VALOR_UNITARIO",
        align: "end",
        divider: "false",
        filterable: false,
        width: 40,
      },
      {
        text: "Valor Total",
        value: "VALOR_TOTAL",
        align: "end",
        divider: "false",
        filterable: false,
        width: 40,
      },
    ] as any;
    this.bonificacoesPedido = [];

    this.formValid = false;

    if (limparProps) {
      this.propEditando = "S";
      this.propCodPedido = 0;
      this.propCodFeira = 0;
      this.propSeqFeira = 0;
      this.propCodFilial = "";
      this.propCodCli = 0;
      this.propCodPlPag = 0;
      this.propCodPlPagCompl = 0;
      this.propCodTabPrecoNegCab = 0;
      this.propCodUsur = 0;
      this.propObservacao = "";
      this.propObservacaoLiberacao = "";
      this.propStatus = "";
    }
  }

  get itemsFeira() {
    return this.entriesFeira.map((entry: any) => {
      const FEIRA =
        entry.CODFEIRA +
        "-" +
        (entry.DESCRICAO.length > this.descriptionLimit
          ? entry.DESCRICAO.slice(0, this.descriptionLimit) + "..."
          : entry.DESCRICAO);

      return Object.assign({}, entry, {FEIRA});
    });
  }

  get itemsFilial() {
    return this.entriesFilial.map((entry: any) => {
      const FILIAL =
        entry.CODFILIAL +
        "-" +
        (entry.FILIAL.length > this.descriptionLimit
          ? entry.FILIAL.slice(0, this.descriptionLimit) + "..."
          : entry.FILIAL);

      return Object.assign({}, entry, {FILIAL});
    });
  }

  get itemsTabNeg() {
    return this.entriesTabNeg.map((entry: any) => {
      const DESCRICAO =
        entry.DESCRICAO.length > this.descriptionLimit
          ? entry.DESCRICAO.slice(0, this.descriptionLimit) + "..."
          : entry.DESCRICAO;

      return Object.assign({}, entry, {DESCRICAO});
    });
  }

  get itemsRCA() {
    return this.entriesRCA.map((entry: any) => {
      const NOME =
        entry.NOME.length > this.descriptionLimit
          ? entry.NOME.slice(0, this.descriptionLimit) + "..."
          : entry.NOME;

      return Object.assign({}, entry, {NOME});
    });
  }

  get itemsCLiente() {
    return this.entriesCliente.map((entry: any) => {
      const DESCCOMPLETACLIENTE =
        entry.DESCCOMPLETACLIENTE.length > this.descriptionLimit
          ? entry.DESCCOMPLETACLIENTE.slice(0, this.descriptionLimit) + "..."
          : entry.DESCCOMPLETACLIENTE;

      return Object.assign({}, entry, {DESCCOMPLETACLIENTE});
    });
  }

  get itemsPlanoPag() {
    return this.entriesPlanoPag.map((entry: any) => {
      const PLANOPAGAMENTO =
        entry.PLANOPAGAMENTO.length > this.descriptionLimit
          ? entry.PLANOPAGAMENTO.slice(0, this.descriptionLimit) + "..."
          : entry.PLANOPAGAMENTO;

      return Object.assign({}, entry, {PLANOPAGAMENTO});
    });
  }

  get itemsPlanoPagCompl() {
    return this.entriesPlanoPagCompl.map((entry: any) => {
      const PLANOPAGAMENTO =
        entry.PLANOPAGAMENTO.length > this.descriptionLimit
          ? entry.PLANOPAGAMENTO.slice(0, this.descriptionLimit) + "..."
          : entry.PLANOPAGAMENTO;

      return Object.assign({}, entry, {PLANOPAGAMENTO});
    });
  }

  @Watch("searchFeira")
  private searchFeiraWatch(): void {
    if (this.itemsFeira.length > 0) return;
    if (this.isLoadingFeira) return;
    this.isLoadingFeira = true;
    this.entriesFeira = (this.$auth.user() as any).FEIRAS;
    if (this.propCodFeira != 0) {
      this.entriesFeira.forEach((values: any) => {
        if (this.propCodFeira === values.CODFEIRA) {
          this.modelFeira = Object.assign({}, values);
        }
      });
    }
    this.isLoadingFeira = false;
  }

  @Watch("searchFilial")
  private searchFilialWatch(): void {
    if (this.itemsFilial.length > 0) return;

    if (this.isLoadingFilial) return;

    this.isLoadingFilial = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    fetch(
      this.baseURL +
      "pesquisas/filiais/" +
      (this.$auth.user() as any).MATRICULA,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesFilial = res;
        if (this.propCodFilial !== "") {
          this.entriesFilial.forEach((values: any) => {
            if (this.propCodFilial === values.CODFILIAL) {
              this.modelFilial = Object.assign({}, values);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoadingFilial = false));
  }

  @Watch("modelFeira")
  private modelFeiraWatch(): void {
    this.modelTabNeg = null;
    this.entriesTabNeg = [];
    this.searchTabNegWatch();
  }

  @Watch("modelFilial")
  private modelFilialWatch(): void {
    this.modelTabNeg = null;
    this.entriesTabNeg = [];
    this.searchTabNegWatch();
  }

  @Watch("searchTabNeg")
  private searchTabNegWatch(): void {
    if (this.modelFilial === null) return;
    if (this.modelFeira === null) return;

    if (this.itemsTabNeg.length > 0) return;

    if (this.isLoadingTabNeg) return;

    this.isLoadingTabNeg = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    fetch(
      this.baseURL +
      `pesquisas/tabpreconegcab/${(this.modelFilial as any).CODFILIAL}/${(this.modelFeira as any).CODFEIRA}/${(this.modelFeira as any).CODFORNEC}`,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesTabNeg = res;
        if (this.propCodTabPrecoNegCab != 0) {
          this.entriesTabNeg.forEach((values: any) => {
            if (this.propCodTabPrecoNegCab === values.CODTABPRECONEGCAB) {
              this.modelTabNeg = Object.assign({}, values);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoadingTabNeg = false));
  }

  @Watch("searchCliente")
  private searchClienteWatch(): void {
    if (this.itemsCLiente.length > 0) return;

    if (this.isLoadingCLiente) return;

    this.isLoadingCLiente = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(
      this.baseURL + "pesquisas/clientes/" + (this.$auth.user() as any).CODUSUR,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesCliente = res;
        if (this.propCodCli != 0) {
          this.entriesCliente.forEach((values: any) => {
            if (this.propCodCli === values.CODCLI) {
              this.modelCliente = Object.assign({}, values);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoadingCLiente = false;
      });
  }

  @Watch("modelCliente")
  private modelClienteWatch(): void {
    this.modelPlanoPag = null;
    this.modelPlanoPagCompl = null;
    this.entriesPlanoPag = [];
    this.entriesPlanoPagCompl = [];
    this.modelRCA = null;
    this.entriesRCA = [];
    this.searchRCAWatch();
    this.searchPlanoPagWatch();
    this.searchPlanoPagComplWatch();
  }

  @Watch("searchPlanoPag")
  private searchPlanoPagWatch(): void {
    if (this.modelCliente === null) return;

    if (this.itemsPlanoPag.length > 0) return;

    if (this.isLoadingPlanoPag) return;

    this.isLoadingPlanoPag = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(
      this.baseURL +
      "pesquisas/planospagamentoscliente/" +
      (this.modelCliente as any).CODCLI,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesPlanoPag = res;
        if (this.propCodPlPag != 0) {
          this.entriesPlanoPag.forEach((values: any) => {
            if (this.propCodPlPag === values.CODPLPAG) {
              this.modelPlanoPag = Object.assign({}, values);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoadingPlanoPag = false));
  }

  @Watch("searchPlanoPagCompl")
  private searchPlanoPagComplWatch(): void {
    if (this.itemsPlanoPagCompl.length > 0) return;

    if (this.isLoadingPlanoPagCompl) return;

    this.isLoadingPlanoPagCompl = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(
      this.baseURL +
      "pesquisas/planospagamentos",
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesPlanoPagCompl = res;
        if (this.propCodPlPagCompl != 0) {
          this.entriesPlanoPagCompl.forEach((values: any) => {
            if (this.propCodPlPagCompl === values.CODPLPAG) {
              this.modelPlanoPagCompl = Object.assign({}, values);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoadingPlanoPagCompl = false));
  }

  @Watch("searchRCA")
  private searchRCAWatch(): void {
    if (this.modelCliente === null) return;

    if (this.itemsRCA.length > 0) return;

    if (this.isLoadingRCA) return;

    this.isLoadingRCA = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(
      this.baseURL +
      "pesquisas/rcascliente/" +
      (this.modelCliente as any).CODCLI +
      "/" +
      ((this.propCodUsur == undefined ? 0 : this.propCodUsur) > 0
        ? String(this.propCodUsur)
        : (this.$auth.user() as any).CODUSUR),
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesRCA = res;
        if (this.propCodUsur != 0) {
          this.entriesRCA.forEach((values: any) => {
            if (this.propCodUsur === values.CODUSUR) {
              this.modelRCA = Object.assign({}, values);
            }
          });
        }
        if (this.propCodPedido !== undefined)
          if (this.propCodPedido > 0) this.carregarParaEdicao();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoadingRCA = false));
  }

  @Watch("itemsPedido")
  private async recalcularPedido() {
    let numSeq = 0;
    let percDesc = 0;
    this.vlrTotalCab = 0;
    this.vlrTotalProdNormal = 0;
    this.vlrTotalProdSuperDesconto = 0;
    this.vlrTotalCustoProdNormal = 0;
    this.vlrTotalCustoProdSuperDesconto = 0;
    this.pctCMVProd = 0;
    this.pctRepresentatividade = 0;
    this.existeItemSuperDesconto = false

    this.qtdeTotalSKU = 0;
    this.vlrTotalBrindeCab = 0;
    this.vlrTotalSaldoCab = 0;

    this.itemsPedido.map(async (entry: any) => {
      numSeq++;
      percDesc = 0;
      entry.NUMSEQ = numSeq;
      if (entry.VLRVENDAFAB > 0 && entry.VLRVENDAPED > 0) {
        percDesc = ((entry.VLRVENDAFAB - entry.VLRVENDAPED) / entry.VLRVENDAFAB) * 100;
      }
      entry.PERCDESCPED = +percDesc.toFixed(4);

      if (entry.SUPER_DESCONTO === 'S') {
        this.existeItemSuperDesconto = true;
      }

      if (entry.QTDEPED > 0) {
        entry.VLRSALDOPED = +(parseFloat(entry.QTDEPED) * (+parseFloat(entry.VLRVENDAPED).toFixed(2) - +parseFloat(entry.VLRVENDAMINSUG).toFixed(2) ));
        entry.VLRSALDOPED += +(parseFloat(entry.QTDEPED) * parseFloat(entry.VLRSALDOFAB));
        entry.VLRTOTALPED = +(parseFloat(entry.QTDEPED) * parseFloat(entry.VLRVENDAPED));
        entry.VLRBRINDEPED = +(parseFloat(entry.VLRTOTALPED) * (parseFloat((this.modelTabNeg as any).VLRBRINDE) * 0.01));
        this.vlrTotalCab += +parseFloat(entry.VLRTOTALPED);
        this.vlrTotalBrindeCab += +parseFloat(entry.VLRBRINDEPED);
        this.vlrTotalSaldoCab += +parseFloat(entry.VLRSALDOPED);

        this.qtdeTotalSKU += 1;

        if (entry.SUPER_DESCONTO === 'S') {
          this.vlrTotalProdSuperDesconto += entry.QTDEPED * entry.VLRVENDAPED;
          this.vlrTotalCustoProdSuperDesconto += entry.QTDEPED * entry.CUSTOFIN;
        } else {
          this.vlrTotalProdNormal += entry.QTDEPED * entry.VLRVENDAPED;
          this.vlrTotalCustoProdNormal += entry.QTDEPED * entry.CUSTOFIN;
        }
      } else {
        entry.VLRSALDOPED = 0;
        entry.VLRTOTALPED = 0;
        entry.VLRBRINDEPED = 0;
      }
      return Object.assign({}, entry);
    });
    // Calculando o CMV
    this.pctCMVProd = ((this.vlrTotalCustoProdNormal + this.vlrTotalCustoProdSuperDesconto) / (this.vlrTotalProdNormal + this.vlrTotalProdSuperDesconto)) * 100;
    // Representatividade dos itens com super desconto
    this.pctRepresentatividade = (this.vlrTotalProdSuperDesconto / this.vlrTotalProdNormal) * 100;

    // if (this.modelTabNeg !== null)
    //   this.vlrTotalSaldoCab -= (this.modelTabNeg as any).VLRBRINDE;

    this.descStatusPedido = "Rever Pedido";

    /*
    console.log(+(this.modelTabNeg as any).QTDE_SKU_MIN);
    console.log(this.qtdeTotalSKU);
    console.log('----');
    console.log(+(this.modelTabNeg as any).PCTREPRESENTATIVIDADE);
    console.log(this.pctRepresentatividade);
    console.log('----');
    console.log(+(this.modelTabNeg as any).PCTCMV);
    console.log(this.pctCMVProd);
     */

    if (this.modelTabNeg !== null) {
      if (
        this.vlrTotalCab >= +(this.modelTabNeg as any).VLRMAXIMO &&
        this.vlrTotalSaldoCab >= +(this.modelTabNeg as any).VLRMINIMO &&
        this.qtdeTotalSKU >= +(this.modelTabNeg as any).QTDE_SKU_MIN &&
        this.ProdutosSuperDescontoValido()) {
        this.descStatusPedido = "Aprovado";
      }
    }
    await this.recalcularBrindeBonificacao()
  }

  private ProdutosSuperDescontoValido() {
    if (!this.existeItemSuperDesconto)
      return true;

    return (
      (this.pctCMVProd <= +(this.modelTabNeg as any).PCTCMV) &&
      (this.pctRepresentatividade <= +(this.modelTabNeg as any).PCTREPRESENTATIVIDADE)
    );
  }

  private async recalcularBrindeBonificacao() {
    this.vlrTotalBrinde = 0;
    this.vlrTotalBrindeGeral = 0;
    for (const item of this.brindesPedido) {
      await this.recalcularBrinde(item)
    }
    this.vlrTotalBonificacao = 0;
    for (const item of this.bonificacoesPedido) {
      await this.recalcularBonificacao(item)
    }
    this.vlrTotalSaldoBrindeBonificacao = ((this.vlrTotalBrindeCab + this.vlrTotalSaldoCab));
    this.pctUsoSaldo = (((this.vlrTotalBonificacao + this.vlrTotalBrinde) / this.vlrTotalSaldoBrindeBonificacao) * 100)
    this.colorUsoSaldo();
  }

  private async recalcularBrinde(item: any) {
    item.VALOR_TOTAL = item.QUANTIDADE * item.VALOR_UNITARIO;
    this.vlrTotalBrindeGeral += item.VALOR_TOTAL;
    this.vlrTotalBrinde += item.CONSUMIRSALDO === 'S' ? item.VALOR_TOTAL : 0;
    //return Object.assign({}, item);
  }

  private async recalcularBonificacao(item: any) {
    item.VALOR_TOTAL = item.QUANTIDADE * item.VALOR_UNITARIO;
    this.vlrTotalBonificacao += item.VALOR_TOTAL;
    //return Object.assign({}, entry);
  }

  private colorUsoSaldo() {
    if (this.pctUsoSaldo > 100)
      return "red"
    else if (this.pctUsoSaldo > 75)
      return "orange"
    else if (this.pctUsoSaldo > 50)
      return "yellow"
    else if (this.pctUsoSaldo > 0)
      return "teal"
  }

  private async iniciarPedido() {
    if (this.isLoadingItemsPedido) return;
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    this.tab = 1;
    this.isLoadingItemsPedido = true;

    await fetch(
      this.baseURL +
      `pesquisas/datasPrevEntrega/${this.propCodPedido}`,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if ((res as any[]).length > 0) {
          this.datasPrevisaoEntrega = res;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await fetch(
      this.baseURL +
      "pesquisas/itenscombopedido/" +
      (this.modelCliente as any).CODCLI +
      "/" +
      (this.modelTabNeg as any).CODTABPRECONEGCAB +
      "/" +
      this.propCodPedido,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if ((res as any[]).length > 0) {
          this.itemsPedido = res;
          this.pedidoIniciado = true;
        } else SendMsg.$emit("Atencao", "Tabela de Preço sem Produtos");
      })
      .catch((err) => {
        this.tab = 0;
        console.log(err);
      })
      .finally(() => {
        this.isLoadingItemsPedido = false;
        this.popularBrindes();
        this.popularBonificacaoes();
        this.getPedidoImpressao(this.propCodPedido || 0);
        if (this.pedidoIniciado) {
          if (this.modelFeira != null)
            this.exibirInfoInd = (this.modelFeira as any).EXIBIRINFOIND === "S";
          SendMsg.$emit("Atencao", "Pedido Iniciado");
        }
      });
  }

  private async popularBrindes() {
    if (this.isLoadingBrindes) return;
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    this.tab = 1;
    this.isLoadingBrindes = true;
    fetch(`${this.baseURL}pesquisas/brindes/${this.propCodPedido}/${(this.modelFeira as any).CODFEIRA}/${(this.modelTabNeg as any).CODFORNEC}`,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if ((res as any[]).length > 0) {
          this.brindesPedido = res;
        }
      })
      .finally(() => {
        this.isLoadingBrindes = false;
        this.recalcularBrindeBonificacao();
      });
  }

  private async popularBonificacaoes() {
    if (this.isLoadingBonificacao) return;
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    this.tab = 1;
    this.isLoadingBonificacao = true;
    fetch(`${this.baseURL}pesquisas/bonificacao/${(this.modelFilial as any).CODFILIAL}/${this.propCodPedido}/${(this.modelFeira as any).CODFEIRA}/${(this.modelTabNeg as any).CODFORNEC}`,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if ((res as any[]).length > 0) {
          this.bonificacoesPedido = res;
        }
      })
      .finally(() => {
        this.isLoadingBonificacao = false;
        this.recalcularBrindeBonificacao();
      });
  }

  private async validarDatasPrevisaoEntrega(data: Date) {
    const dataCorrente = new Date(data);
    const dataAtual = new Date();
    if (dataCorrente < dataAtual) {
      SendMsg.$emit(
        "Atencao",
        "Período de previsão de entrega está inváido."
      );
      return false;
    }
    return true;
  }

  private async gravarPedido(exibirLog: boolean): Promise<void> {
    this.dialogGravarPedido = false;
    this.dialogGravarPedidoLog = false;

    // Verificando se o pedido pode sereditado
    if (this.propEditando === 'N') {
      SendMsg.$emit(
        "Atencao",
        "Você selecionou um pedido na posição PL e que já foi enviado para o ERP. Não é possível alterar o pedido."
      );
      return;
    }

    // Verificando se o pedido está com quantidade mínima de SKU
    if (
      ((this.modelTabNeg as any).QTDE_SKU_MIN > 0) &&
      ((this.modelTabNeg as any).QTDE_SKU_MIN > this.qtdeTotalSKU)) {
      SendMsg.$emit(
        "Atencao",
        `O pedido não atende a quantidade mínima de SKU. Mínimo: ${(this.modelTabNeg as any).QTDE_SKU_MIN} SKU.`
      );
      return;
    }

    if (this.existeItemSuperDesconto) {

      if (this.vlrTotalProdSuperDesconto === 0 ) {
        SendMsg.$emit(
          "Atencao",
          `Nenhum produto com super desconto foi informado.`
        );
        return ;
      }

      if (!this.ProdutosSuperDescontoValido()) {
        if (this.pctCMVProd > +(this.modelTabNeg as any).PCTCMV) {
          SendMsg.$emit(
            "Atencao",
            `CMV do pedido ultrapassado `
          );
        } else if (this.pctRepresentatividade > +(this.modelTabNeg as any).PCTREPRESENTATIVIDADE) {
          SendMsg.$emit(
            "Atencao",
            `Representatividade do produto super desconto ultrapassado`
          );
        }
        return;
      }
    }

    // Validando as datas de previsão de entrega
    if (this.datasPrevisaoEntrega.length > 0) {
      let datasPrevisaoEntregaValida = true;
      const validaData = async (date: Date) => {
        if ((new Date(date)) < (new Date())) {
          datasPrevisaoEntregaValida = false
          return false;
        }
      }
      for (const date of this.datasPrevisaoEntrega) {
        if (!await validaData(date))
          break;
      }
      if (!datasPrevisaoEntregaValida) {
        SendMsg.$emit(
          "Atencao",
          "Período de previsão de entrega está inváido."
        );
        this.dialogGravarPedido = false;
        return;
      }
    }

    // Montando o objeto do pedido
    this.pedido = {
      CODPEDIDO: this.propCodPedido,
      CODFEIRA: (this.modelFeira as any).CODFEIRA,
      SEQFEIRA: this.propSeqFeira = 0 ? (this.modelFeira as any).ULT_SEQFEIRA : this.propSeqFeira,
      CODFILIAL: (this.modelFilial as any).CODFILIAL,
      CODCLI: (this.modelCliente as any).CODCLI,
      CODPLPAG: (this.modelPlanoPag as any).CODPLPAG,
      CODCOB: "BK",
      CODTABPRECONEGCAB: (this.modelTabNeg as any).CODTABPRECONEGCAB,
      MATRICULA: (this.$auth.user() as any).MATRICULA,
      CODUSUR: (this.modelRCA as any).CODUSUR,
      VLRBRINDE: 0,
      VLRTOTAL: this.vlrTotalCab,
      VLRSALDO: this.vlrTotalSaldoCab,
      QTDE_SKU_MIN: (this.modelTabNeg as any).QTDE_SKU_MIN,
      STATUS: "OR",
      CONTATO: "",
      OBSERVACAO: this.observacao,
      OBSERVACAO_LIBERACAO: this.observacaoLiberacao,
      PCTBRINDE: 0,
      CODPLPAGCOMPL: this.utilizaPlanoCompl ? (this.modelPlanoPagCompl as any).CODPLPAG : 0,
      UTILIZAPLANOCOMPL: this.utilizaPlanoCompl ? "S" : "N",
      ENVIARPOREMAIL: this.espelhoPorEmail,
      STATUSLOG: "",
      itens: [],
      brindes: [],
      bonificacoes: [],
      datasPrevisaoEntrega: this.datasPrevisaoEntrega
    } as any;

    // Define o Padrão de acordo com o selecionado na tela
    (this.pedido as any).STATUS = this.statuspedido;
    if (((this.modelFeira as any).PERMITIRLIBERAR !== "S") && ((this.pedido as any).STATUS !== "OR")) {
      if (((this.vlrTotalBrinde + this.vlrTotalBonificacao) > this.vlrTotalSaldoBrindeBonificacao) &&
        ((this.vlrTotalBrinde + this.vlrTotalBonificacao) > 0)) {
        SendMsg.$emit(
          "Atencao",
          "Saldo de brinde/bonificação foi ultrapassado."
        );
        (this.pedido as any).STATUS = "PA";
        (this.pedido as any).STATUSLOG = "Saldo de brinde/bonificação foi ultrapassado";
      } else if (((this.pedido as any).UTILIZAPLANOCOMPL === 'S') && ((this.pedido as any).CODPLPAG !== (this.pedido as any).CODPLPAGCOMPL)) {
        SendMsg.$emit(
          "Atencao",
          "Pedido com plano de pagamento complementar."
        );
        return;
        (this.pedido as any).STATUSLOG = "Pedido com plano de pagamento complementar.";
      } else if (this.vlrTotalCab < (this.modelTabNeg as any).VLRMAXIMO) {
        SendMsg.$emit(
          "Atencao",
          "Pedido não atendeu o valor mínimo."
        );
        (this.pedido as any).STATUSLOG = "Pedido não atendeu o valor mínimo.";
        return;
      } else if (this.vlrTotalSaldoCab < (this.modelTabNeg as any).VLRMINIMO) {
        SendMsg.$emit(
          "Atencao",
          "Pedido não atendeu o valor mínimo do saldo."
        );
        (this.pedido as any).STATUS = "PA";
        (this.pedido as any).STATUSLOG = "Pedido não atendeu o valor mínimo do saldo.";
      } else {
        this.statuspedido = 'PL';
        (this.pedido as any).STATUS = this.statuspedido;
      }
    } else if (((this.modelFeira as any).PERMITIRLIBERAR === "S") && ((this.pedido as any).STATUS === "PA")) {
      this.statuspedido = 'PL';
      (this.pedido as any).STATUS = this.statuspedido;
    }

    if (exibirLog === true) {
      if ((((this.modelFeira as any).PERMITIRLIBERAR === "S") || ((this.pedido as any).STATUS === "PA")) && ((this.pedido as any).CODPEDIDO > 0)) {
        this.dialogGravarPedidoLog = true;
        return;
      }
    }

    this.itemsPedido.map((item: any) => {
      if (item.QTDEPED > 0) {
        (this.pedido as any).itens.push({
          CODPEDIDOITEM: item.CODPEDIDOITEM,
          CODPEDIDO: item.CODPEDIDO,
          CODPROD: item.CODPROD,
          NUMSEQ: item.NUMSEQ,
          VLRVENDAFAB: item.VLRVENDAFAB,
          QTDESUG: item.QTDESUG,
          PERCDESCSUG: item.PERCDESCSUG,
          VLRVENDASUG: item.VLRVENDASUG,
          VLRVENDAMINSUG: item.VLRVENDAMINSUG,
          VLRSALDOFAB: item.VLRSALDOFAB,
          QTDEPED: item.QTDEPED,
          PERCDESCPED: item.PERCDESCPED,
          VLRVENDAPED: item.VLRVENDAPED,
          VLRTOTALPED: item.VLRTOTALPED,
          VLRSALDOPED: item.VLRSALDOPED,
        } as never);
      }
    });

    this.brindesPedido.map((brinde: any) => {
      if (brinde.QUANTIDADE > 0) {
        (this.pedido as any).brindes.push({
          CODPEDIDOBRINDE: brinde.CODPEDIDOBRINDE,
          CODPEDIDO: brinde.CODPEDIDO,
          CODBRINDE: brinde.CODBRINDE,
          QUANTIDADE: brinde.QUANTIDADE,
          VALOR_UNITARIO: brinde.VALOR_UNITARIO,
          VALOR_TOTAL: brinde.VALOR_TOTAL,
        } as never);
      }
    });

    this.bonificacoesPedido.map((bonificacao: any) => {
      if (bonificacao.QUANTIDADE > 0) {
        (this.pedido as any).bonificacoes.push({
          CODPEDIDOBONIFICACAO: bonificacao.CODPEDIDOBONIFICACAO,
          CODPEDIDO: bonificacao.CODPEDIDO,
          CODBONIFICACAO: bonificacao.CODBONIFICACAO,
          QUANTIDADE: bonificacao.QUANTIDADE,
          VALOR_UNITARIO: bonificacao.VALOR_UNITARIO,
          VALOR_TOTAL: bonificacao.VALOR_TOTAL,
        } as never);
      }
    });

    if (this.isSavePedido) return;

    this.dialogGravarPedido = false;
    this.dialogGravando = true;
    this.isSavePedido = true;

    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    await fetch(this.baseURL + "pesquisas/gravarpedidofeira", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify((this.pedido as any)),
      mode: "cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        const {aceito, mensagem, codNovoPedido, itensSemEstoque, bonificadosSemEstoque} = res
        if (aceito) {
          this.gravacaoCodPedido = codNovoPedido
          if (((this.pedido as any).STATUS === "OR")) {
            this.gravacaoMensagem = `Pedido Gravado com o Nº ${this.gravacaoCodPedido}, gravado como Orçamento.`
          } else if (((this.pedido as any).STATUS === "PL")) {
            this.gravacaoMensagem = `Pedido Gravado com o Nº ${this.gravacaoCodPedido}, gravado como Liberado.`
          } else {
            this.gravacaoMensagem = `Pedido Gravado com o Nº ${this.gravacaoCodPedido}, gravado como Análise.`
          }
          this.getPedidoImpressao(this.gravacaoCodPedido || 0)
        } else {
          this.isSavePedido = false;
          for (const itemSemEstoque of itensSemEstoque) {
            (this.itemsPedido.filter(item => (item as any).CODPROD === itemSemEstoque.CODPROD)[0] as any).QTDEESTDISP = (itemSemEstoque as any).QTDEESTDISP;
          }
          for (const bonificadoSemEstoque of bonificadosSemEstoque) {
            (this.bonificacoesPedido.filter(item => (item as any).CODPROD === bonificadoSemEstoque.CODPROD)[0] as any).ESTOQUE_DISP = (bonificadoSemEstoque as any).ESTOQUE_DISP;
          }
          this.dialogGravando = false;
          this.dialogGravado = true;
          this.msgDialogGravado = mensagem;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.dialogGravarPedido = false;
      });
  }

  private PedidoGravado() {
    this.dialogGravando = false
    this.pedidoIniciado = false;
    this.inicializar(true);
  }

  private cancelarPedido(): void {
    this.pedidoIniciado = false;
    SendMsg.$emit("Atencao", "Pedido Iniciado");
  }

  private itemRowBackground(item: any) {
    return item.QTDEPED > item.QTDEESTDISP
      ? "red lighten-5 caption"
      : item.QTDEPED > 0 ? "green lighten-4 caption" : "grey lighten-4 caption";
  }

  private brindeRowBackground(item: any) {
    if (item.CONSUMIRSALDO === 'S')
      return item.QUANTIDADE > 0 ? "green lighten-4 caption" : "white";
    else
      return item.QUANTIDADE > 0 ? "orange lighten-4 caption" : "white";
  }

  private bonificacaoRowBackground(item: any) {
    return item.QUANTIDADE > item.ESTOQUE_DISP
      ? "red lighten-5 caption"
      : item.QUANTIDADE > 0 ? "green lighten-4 caption" : "white";
  }

  @Watch("dialog")
  private dialogx(val: boolean) {
    if (val) {
      this.$nextTick((this.$refs.iptValor as any));
    } else {
      this.cancelEditItem();
    }
  }

  @Watch("editedItem.VLRVENDAPED")
  private calcularItemEditadoValorVenda() {
    if ((this.editedItem as any).VLRVENDAFAB > 0 && (this.editedItem as any).VLRVENDAPED > 0) {
      (this.editedItem as any).PERCDESCPED = (((this.editedItem as any).VLRVENDAFAB - (this.editedItem as any).VLRVENDAPED) / (this.editedItem as any).VLRVENDAFAB) * 100;
    }
    if ((this.editedItem as any).VLRVENDAFAB > 0 && (this.editedItem as any).PERCDESCPED > 0) {
      (this.editedItem as any).VLRVENDAPED = parseFloat(((this.editedItem as any).VLRVENDAFAB * ((100 - (this.editedItem as any).PERCDESCPED) * 0.01)).toFixed(2));
    }
  }

  @Watch("editedItem.PERCDESCPED")
  private calcularItemEditadoPercentual() {
    if ((this.editedItem as any).VLRVENDAFAB > 0 && (this.editedItem as any).PERCDESCPED > 0) {
      (this.editedItem as any).VLRVENDAPED = ((this.editedItem as any).VLRVENDAFAB * ((100 - (this.editedItem as any).PERCDESCPED) * 0.01));
    }
  }

  private clickRow(item: any) {
    this.editItem(item);
  }

  private editItem(item: any) {

    if (!!this.isSavePedido || !this.pedidoIniciado || this.propEditando === 'N') {
      SendMsg.$emit("Atencao", "Pedido em modo de visualização, não pode ser editado");
      return;
    }

    this.editedItemIndex = this.itemsPedido.indexOf(item as never);
    this.editedItem = Object.assign({}, item);
    /*
    if (
      (this.editedItem as any).PERMITIR_AUMENTAR_VLRVENDASUG === "N" &&
      (this.editedItem as any).PERMITIR_DIMINUIR_VLRVENDASUG === "N" &&
      (this.editedItem as any).PERMITIR_AUMENTAR_QTDESUG === "N" &&
      (this.editedItem as any).PERMITIR_DIMINUIR_QTDESUG === "N" ||
      (this.editedItem as any).SUPER_DESCONTO === "S"
    ) {
      SendMsg.$emit("Atencao", "Item não pode ser editado.");
      this.cancelEditItem();
      return;
    }
    */
    this.dialog = true;
  }

  private cancelEditItem() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItemIndex = -1;
    });
  }

  private saveInsertOrUpdateItem() {
    if (this.editedItemIndex > -1) {
      if ((this.editedItem as any).PERMITIR_AUMENTAR_VLRVENDASUG === "N") {
        if (
          parseFloat((this.editedItem as any).VLRVENDAPED).toFixed(2) > parseFloat((this.editedItem as any).VLRVENDASUG).toFixed(2)
        ) {
          SendMsg.$emit(
            "Atencao",
            "Náo é permitido aumentar o valor deste item."
          );
          return;
        }
      }
      if ((this.editedItem as any).PERMITIR_DIMINUIR_VLRVENDASUG === "N") {
        if (
          parseFloat((this.editedItem as any).VLRVENDAPED).toFixed(2) < parseFloat((this.editedItem as any).VLRVENDASUG).toFixed(2)
        ) {
          SendMsg.$emit(
            "Atencao",
            "Náo é permitido diminuir o valor deste item."
          );
          return;
        }
      }
      if ((this.editedItem as any).PERMITIR_AUMENTAR_QTDESUG === "N") {
        if (
          (this.editedItem as any).QTDEPED > (this.editedItem as any).QTDESUG
        ) {
          SendMsg.$emit(
            "Atencao",
            "Náo é permitido aumentar a quantidade deste item."
          );
          return;
        }
      }
      if ((this.editedItem as any).PERMITIR_DIMINUIR_QTDESUG === "N") {
        if (
          (this.editedItem as any).QTDEPED < (this.editedItem as any).QTDESUG
        ) {
          SendMsg.$emit(
            "Atencao",
            "Náo é permitido diminuir a quantidade deste item."
          );
          return;
        }
      }

      Object.assign(this.itemsPedido[this.editedItemIndex], this.editedItem);
      this.recalcularPedido();
    } else {
      this.itemsPedido.push(this.editedItem as never);
    }
    this.cancelEditItem();
  }

  private insertOrUpdateBrinde(item: any, value: number) {
    const indexBrinde = this.brindesPedido.indexOf(item as never);
    const brinde = Object.assign({}, item);
    if (indexBrinde > -1) {
      if (((brinde as any).QUANTIDADE > 0) && (value === -1)) {
        ((brinde as any).QUANTIDADE as number)--;
      } else if (value >= 1) {
        ((brinde as any).QUANTIDADE as number)++;
      }
      Object.assign(this.brindesPedido[indexBrinde], brinde);
    }
    this.recalcularBrindeBonificacao();
  }

  private EditItemBonificado(item: any) {
    this.dialogItemBonificado = true;
    this.idxItemBonifado = this.bonificacoesPedido.indexOf(item as never);
    this.itemBonificado = Object.assign({}, item);
    this.qtdeItemBonificado = 0;
    if (this.itemBonificado) {
      this.qtdeItemBonificado = (this.itemBonificado as any).QUANTIDADE;
    }
  }

  private GravarItemBonificado() {
    this.dialogItemBonificado = false;
    if (this.idxItemBonifado > -1) {
      (this.itemBonificado as any).QUANTIDADE = this.qtdeItemBonificado;
      Object.assign(this.bonificacoesPedido[this.idxItemBonifado], this.itemBonificado);
    }
    this.recalcularBrindeBonificacao();
  }


  private colorStatus(qtdePed: number, vlrSaldo: number) {
    if (qtdePed === 0) return "gray";
    else if (vlrSaldo >= 0) return "blue";
    else if (vlrSaldo < 0) return "red";
    else return "green";
  }

  private colorSeta(value: boolean) {
    if (value) {
      return "green";
    } else {
      return "red";
    }
  }

  private getColunasIndustria() {
    return this.colunasIndustria;
  }

  private zerarQuantidades() {
    this.itemsPedido.map((entry: any) => {
      entry.QTDEPED = 0;
    });
    this.recalcularPedido();
  }

  private exibirColunasIndustria() {
    if (!this.colunasIndustria) {
      this.itemsPedidoHeaders.splice(12, 0, {
        text: "Saldo Ped.",
        value: "VLRSALDOPED",
        align: "end",
        filterable: false,
        divider: "false",
        width: 30,
        class: "red lighten-3 caption",
      } as never);
      this.itemsPedidoHeaders.splice(13, 0, {
        text: "Preço Venda Min.",
        value: "VLRVENDAMINSUG",
        align: "end",
        filterable: false,
        divider: "false",
        class: "red lighten-3 caption",
        width: 30,
      } as never);
      this.itemsPedidoHeaders.splice(14, 0, {
        text: "Qtde. Sug.",
        value: "QTDESUG",
        align: "end",
        filterable: false,
        divider: "false",
        class: "red lighten-3 caption",
        width: 30,
      } as never);
      this.itemsPedidoHeaders.splice(15, 0, {
        text: "% Desc. Sug.",
        value: "PERCDESCSUG",
        align: "end",
        filterable: false,
        divider: "false",
        class: "red lighten-3 caption",
        width: 30,
      } as never);
      this.itemsPedidoHeaders.splice(16, 0, {
        text: "Preço Venda Sug.",
        value: "VLRVENDASUG",
        align: "end",
        filterable: false,
        divider: "false",
        class: "red lighten-3 caption",
        width: 30,
      } as never);
      this.itemsPedidoHeaders.splice(17, 0, {
        text: "Saldo Ind.",
        value: "VLRSALDOFAB",
        align: "end",
        filterable: false,
        divider: "false",
        width: 30,
        class: "red lighten-3 caption"
      } as never);
      this.itemsPedidoHeaders.splice(18, 0, {
        text: "Vlr. Brinde",
        value: "VLRBRINDEPED",
        align: "end",
        filterable: false,
        divider: "false",
        class: "red lighten-3 caption",
        width: 30,
      } as never);
    } else {
      this.itemsPedidoHeaders.splice(12, 7);
    }
    this.colunasIndustria = !this.colunasIndustria;
    this.captionInfoInd = this.colunasIndustria
      ? "Ocultar Ind."
      : "Ind.";
  }

  private carregarParaEdicao() {
    if (this.propObservacao) this.observacao = this.propObservacao;
    if (this.propObservacaoLiberacao) this.observacaoLiberacao = this.propObservacaoLiberacao;
    this.utilizaPlanoCompl = this.propUtilizaPlanoCompl === 'S';
    this.statuspedido = 'PL';
    if (this.propStatus === 'OR' || this.propStatus === 'PA')
      this.statuspedido = this.propStatus;
    this.iniciarPedido();
  }

  private async calcularItemMultiplicadoItem(item: any) {
    if (this.qtdeMultiplicadora <= 1) return
    item.QTDEPED = item.QTDEPED * this.qtdeMultiplicadora;
    this.dialogMultiplicador = false;
  }

  private async multiplicarPedido() {
    for (const item of this.itemsPedido) {
      await this.calcularItemMultiplicadoItem(item);
    }
    await this.recalcularPedido()
  }

  private vlrCalculoCmvValido() {
    if (!this.existeItemSuperDesconto)
      return 0;

    return (+(this.modelTabNeg as any).PCTCMV - this.pctCMVProd);
  }

  private vlrCalculoRepresentatividadeValido() {
    if (!this.existeItemSuperDesconto)
      return 0;

    return +(this.modelTabNeg as any).PCTREPRESENTATIVIDADE - this.pctRepresentatividade;
  }



  private async getPedidoImpressao(codPedido: number) {
    console.log(['pedido -> ', codPedido])
    if (codPedido === 0) return []
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    await fetch(
      this.baseURL +
      `pesquisas/pedidoRelatorio?codPedido=${codPedido}`,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.pedidoRelatorio = res;
      })
      .catch((err) => {
        console.log(err);
      })
    console.log(this.pedidoRelatorio);
  }
}
