import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: require('@/screens/Home/Home.vue').default,
    meta: { auth: true },
    children: [{
      path: "/pedidovendafeira",
      name: "pedidovendafeira",
      props: true,
      component: require("@/screens/Feira/Pedido/PedidoVendaFeira.vue").default,
    },
    {
      path: "/acompanhamentofeira",
      name: "acompanhamentofeira",
      component: require("@/screens/Feira/Acompanhamento/AcompanhamentoFeira.vue").default,
    },
    {
      path: "/clientesrca",
      name: "clientesrca",
      component: require("@/screens/CashBack/Clientes/ClientesRca.vue").default,
    }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: require('@/screens/Login/Login.vue').default,
    meta: { auth: false },
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Esse processo simula a propriedade router e força o preenchimento com o router
// @ts-ignore
(Vue as any).router = router;

export default router;
