
export default {
  name: 'totalizador',
  props: {
    descStatusPedido: {},
    getColunasIndustria: {},
    vlrTotalBrindeCab: {},
    vlrTotalCab: {},
    vlrTotalSaldoCab: {},
    existeItemSuperDesconto: Boolean,
    vlrCmv: Boolean,
    vlrRepresentatividade: Boolean
  }
}
