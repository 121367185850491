
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
})
export default class ClientesRca extends Vue {
  private baseURL = "https://server.grupopalmed.com.br:4004/api/";
  //private baseURL = "http://localhost:4005/api/";

  private keyFindGrid = "";
  private headerGrid = [];
  private itensGrid = [];

  mounted() {
    this.inicializar();
  }

  inicializar() {
    this.headerGrid = [
      {text: "Cód. Cli", value: "CODCLI"},
      {text: "CNPJ", value: "CGCENT"},
      {text: "Razão Social", value: "CLIENTE"},
      {text: "Fantasia", value: "FANTASIA"},
      {text: "Responsável", value: "RESP_NOME"},
      {text: "Celular", value: "RESP_CELULAR"},
      // {text: "E-Mail", value: "RESP_EMAIL"},
      // {text: "UF", value: "RESP_UF"},
      {
        text: "Link",
        value: "actions",
        align: "center",
        sortable: false,
        filterable: false,
      },

    ] as any;
    this.itensGrid = [];
    this.keyFindGrid = "";
    this.popularComboCliente();
  }

  private popularComboCliente(): void {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    fetch(
      this.baseURL + "pesquisas/clientes-cashback/" + (this.$auth.user() as any).CODUSUR,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.itensGrid = res;
      })
      .catch((err) => {
        console.log(err);
      })

  }

  private goCashBack(codcli: number) {
      window.open(`https://cashback.grupopalmed.com.br/dlextrato/${codcli}`)
      //window.location.href=`https://cashback.grupopalmed.com.br/dlextrato/${codcli}`
  }

}
