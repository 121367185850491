import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/vue-store';
import vuetify from './plugins/vue-vuetify';
import './plugins/vue-axios';
import './auth/vue-auth';
import './plugins/vue-mask';
import './plugins/vue-numeral-filter';
import "./plugins/vuetify-money.js";

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),

}).$mount('#app');
