import Vue from 'vue';
import auth from "@websanova/vue-auth";
// @ts-ignore
import authBearer from "@websanova/vue-auth/drivers/auth/bearer.js";
// @ts-ignore
import httpAxios from "@websanova/vue-auth/drivers/http/axios.1.x.js";
// @ts-ignore
import routerVueRouter from "@websanova/vue-auth/drivers/router/vue-router.2.x.js";

// @ts-ignore
Vue.use(auth, {
  auth: authBearer,
  http: httpAxios,
  router: routerVueRouter,
  rolesKey: "type",
  notFoundRedirect: { name: "login" },
  loginData: {
    url: "/login",
    method: "POST",
    redirect: "/",
    fetchUser: true,
  },
  logoutData: {
    url: "/logout",
    method: "POST",
    redirect: "/",
    makeRequest: false,
  },
  fetchData: {
    url: "/fetchData",
    method: "GET",
    enabled: true,
  },
  refreshData: {
    url: "/refresh",
    method: "GET",
    enabled: true,
    interval: 30,
    atInit: true,
  },
  registerData: {
    url: "/register",
    method: "POST",
    redirect: "/login",
  },
});
