import Vue from 'vue';
import "@mdi/font/css/materialdesignicons.css";
import Vuetify from 'vuetify';
import pt from 'vuetify/src/locale/pt'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {pt},
    current: 'pt'
  },
  theme: {
    dark: false,
    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: undefined,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: "#48887B",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#da7a34",
        success: "#4CAF50",
        warning: "#FFC107",
        systembar: "#da7a34",
        appbar: "#48887B",
      },
      dark: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        systembar: "#da7a34",
        appbar: "#48887B",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      envelope: "mdi-email",
      lock: "mdi-lock",
      user: "mdi-account",
      phone: "mdi-phone",
      id: "mdi-card-account-details",
      eyeon: "mdi-eye",
      eyeoff: "mdi-eye-off",
      dollar: "mdi-currency-usd",
      document: "mdi-file-document",
      home: "mdi-home",
      date: "mdi-calendar-range",
      search: "mdi-magnify",
      pdf: "mdi-pdf-box",
      xml: "mdi-xml",
      warning: "mdi-warning",
      exit: "mdi-exit-to-app",
    },
  }
});

